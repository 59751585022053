<template>
  <div class="p">
    <h1>Autom8 Privacy Policy</h1>

    <p><strong>Effective Date:</strong> 25 Jan, 2024</p>

    <p>
      Thank you for using Autom8. This Privacy Policy outlines how we collect,
      use, and protect your information. Please read this policy carefully to
      understand our practices regarding your data.
    </p>

    <h3>1. Secure Transactions:</h3>
    <p>
      Autom8 is committed to ensuring the security of your transactions. We
      employ industry-standard security measures to protect your personal and
      financial information.
    </p>

    <h3>2. Google SSO and Consent Authorization:</h3>
    <p>
      Autom8 utilizes Google Single Sign-On (SSO) for a seamless and secure
      login experience. During consent authorizations, Autom8 only requests
      essential permissions necessary for optimal functionality, ensuring a safe
      and transparent user experience.
    </p>

    <h3>3. 24/7 Telegram Support:</h3>
    <p>
      We offer round-the-clock support through Telegram to assist you with any
      inquiries or concerns. Our support team is dedicated to providing prompt
      and effective solutions.
    </p>

    <h3>4. Safe and Fully Secure:</h3>
    <p>
      Your privacy and security are our top priorities. We implement robust
      measures to safeguard your data, ensuring a safe environment for all
      users.
    </p>

    <h3>5. Manual Refunds:</h3>
    <p>
      In the rare event of a transaction failure, Autom8 provides manual refund
      options to address any inconvenience promptly. Our commitment is to ensure
      a seamless user experience.
    </p>

    <h3>6. Priority Processing:</h3>
    <p>
      We prioritize the processing of your requests to deliver a swift and
      efficient service. Your transactions and inquiries are handled with the
      utmost urgency.
    </p>

    <h3>7. CashApp Payments Status:</h3>
    <p>
      Autom8 solely displays the status of CashApp payments. No additional
      information is accessed or stored, ensuring strict adherence to privacy
      and security standards.
    </p>

    <h3>8. Google Cloud Platform Approval:</h3>
    <p>
      Autom8 adheres to Google Cloud Platform's guidelines and standards. Our
      practices align with industry best practices to ensure the approval and
      endorsement of our services.
    </p>

    <h3>9. Other Necessary Information:</h3>
    <p>
      This Privacy Policy covers data collection, storage, and processing
      practices. We may collect certain non-personal information for analytics
      and improvement purposes. We do not share your information with third
      parties, except as required by law.
    </p>

    <h3>10. Changes to Privacy Policy:</h3>
    <p>
      Autom8 reserves the right to update this Privacy Policy. Users will be
      notified of any significant changes.
    </p>

    <p>
      By using Autom8, you agree to the terms outlined in this Privacy Policy.
      If you have any questions or concerns, please contact us through our 24/7
      Telegram support.
    </p>

    <footer>
      <p>&copy; 2024 Autom8. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped lang="scss">
.p {
  height: calc(100vh - 250px);
  margin: 30px 0;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  display: flex;
  align-items: start;
  justify-content: start;
  p {
    color: white;
  }
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none;
}
.p::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
footer {
  margin-bottom: 100px;
}
</style>
