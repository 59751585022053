<template>
  <div class="p">
    <h2>Please wait, logging you to the dashboard!</h2>
    <div>
      <div class="loader-c">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { setToken, setUserObject } from "@/services/localStorage";
import { get } from "@/services/http";
import { BaseUrl } from "@/utils/api-routes";

export default {
  name: "UserLogin",
  data() {
    return { isLoading: false };
  },
  methods: {
    async sendAxiosRequest(url) {
      try {
        const data = await get(BaseUrl + "/user/login" + url);
        if (data?.access_token) {
          // setToken(data.access_token);
          // setUserObject(data);
        }
        this.$router.push({ name: "home" });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    if (this.$route.fullPath.includes("callback")) {
      this.sendAxiosRequest(this.$route.fullPath);
    }
  },
};
</script>

<style scoped lang="scss">
.p {
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  .inner-content {
    display: flex;
  }
  .left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    span {
      margin: 05px 0px;
      display: flex;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
        margin: 0px 05px 0px 0px;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }
}

button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 35px;
  width: 250px;
  transition: background-color 0.3s ease;
  padding: 20px;
  border-radius: 50px;

  &:hover {
    background-color: rgb(255 255 255 / 11%);
  }
}

img {
  height: 40px;
}

@media (min-width: 320px) {
  .video-container {
    iframe {
      width: 768px;
      height: 140px;
    }
  }
  .content {
    h4 {
      text-align: center;
    }

    .right {
      button {
        font-size: 16px;
        width: 70px;
        img {
          width: 30px;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .video-container {
    margin-top: 0px 0px 10px 0px;
    iframe {
      width: 768px;
      height: 200px;
    }
  }
  .content {
    h4 {
      text-align: center;
    }

    .right {
      display: flex;
      justify-content: center;
    }
  }
}
@media (min-width: 1024px) {
  .video-container {
    margin-top: 40px;
    iframe {
      width: 768px;
      height: 240px;
    }
  }
}

@media (min-width: 1400px) {
  .video-container {
    margin: 30px 0px 30px 0px;
    iframe {
      width: 768px;
      height: 250px;
    }
  }
}
iframe {
  border-radius: 8px;
}
</style>
