<template>
  <div :class="[this.mode]">
    <div class="t"></div>
    <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
  </div>
  <div class="container">
    <NavBar />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import NavBar from "@/components/Nav.vue";
import AppFooter from "@/components/Footer.vue";
import { setMode, getMode } from "@/services/localStorage";

export default {
  name: "App",
  components: {
    NavBar,
    AppFooter,
  },
  data() {
    return {
      mode: "",
    };
  },
  created() {
    this.mode = getMode();
    this.emitter.on("mode-change", (data) => {
      this.mode = data.mode;
      setMode(this.mode);
    });
  },
};
</script>

<style scoped lang="scss">
.dark-mode {
  background: linear-gradient(60deg, #25274d 0%, #0c0c28 100%);
}

.light-mode {
  background: linear-gradient(60deg,
      rgba(84, 58, 183, 1) 0%,
      rgba(0, 172, 193, 1) 100%);
}

.t {
  height: 80vh;
}

.waves {
  position: relative;
  width: 100%;
  height: 20vh;
  margin-bottom: -7px;
  min-height: 100px;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -6s;
  animation-duration: 30s;
}

.parallax>use:nth-child(2) {
  animation-delay: -10s;
  animation-duration: 30s;
}

.parallax>use:nth-child(3) {
  animation-delay: -14s;
  animation-duration: 30s;
}

.parallax>use:nth-child(4) {
  animation-delay: -18s;
  animation-duration: 30s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}
</style>
